function padLeftZero(str) {
  return (`00${str}`).substr(str.length)
}
const day = ['一', '二', '三', '四', '五', '六', '日']

export function formatDate(d, format = 'yyyy-MM-dd') {
  const date = new Date(d)
  let fmt = format
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear().toString()).substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = (o[k] || '').toString()
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt.replace(/NaN/g, '')
}

export function diffNow(date, fmt, fmt1 = 'MM-dd') {
  const _date = new Date(date)
  const now = new Date()
  const value = (now - _date) / 60 / 60 / 1000; // 单位小时
  if (value * 60 < 1) {
    return parseInt(value * 60 * 60, 10) + '秒前'
  }
  else if (value < 1) {
    return parseInt(value * 60, 10) + '分钟前'
  }
  else if (value < 24) {
    return parseInt(value, 10) + '小时前'
  } else if (value <= 24 * 2) {
    return '昨天'
  } else if (value <= 24 * 3) {
    return '前天'
  } else if (value <= 24 * 3) {
    return parseInt(value / 24, 10) + '天前'
  } else if (value <= 24 * 7) {
    return `周${day[_date.getDay() - 1]}`
  } else {
    if (new Date(date).getFullYear() === new Date().getFullYear()) {
      return formatDate(date, fmt1)
    }
    return formatDate(date, fmt)
  }
}

export function getDay(d) {
  const date = new Date(d)
  let week
  switch (date.getDay()) {
    case 0:
      week = '周日'
      break
    case 1:
      week = '周一'
      break
    case 2:
      week = '周二'
      break
    case 3:
      week = '周三'
      break
    case 4:
      week = '周四'
      break
    case 5:
      week = '周五'
      break
    case 6:
      week = '周六'
      break
  }
  return week
}
