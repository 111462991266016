<template>
  <div v-if="detail">
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">银行账户信息</a>
    </div>
    <div class="top-row">
      <div class="top-left">
        <div class="company-picture">
          <img :src="detail.logo" alt="" />
        </div>
        <div class="company-base-content">
          <div>公司代码：{{ detail.code }}</div>
          <div>{{ detail.name }}</div>
          <div class="tag-row">
            <div class="tag-item">
              {{ { 0: "集成类", 1: "供货方", 2: "招标方" }[detail.type] }}
            </div>
            <!-- <div>
              <el-link type="primary" :underline="false">详情</el-link>
            </div> -->
          </div>
        </div>
      </div>
      <div class="top-right">
        <div>会员到期日</div>
        <div>{{ detail.vipEndTime }}</div>
        <div style="display: flex; justify-content: space-around">
          <el-link type="primary" :underline="false" @click="cancel"
            >查看会员信息</el-link
          >
          <el-link
            type="primary"
            :underline="false"
            @click="$router.push('/a-company/company/member/view')"
            >立即续费</el-link
          >
        </div>
      </div>
    </div>

    <div class="border-wrap-box">
      <el-form size="small" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="8">
            <el-form-item label="手机号码">{{ detail.bindPhone }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司资质"
              >{{ detail.sysIntegration }}资质</el-form-item
            >
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">{{
              { 0: "草稿", 1: "待审批", 2: "启用", 3: "审批失败" }[
                detail.status
              ]
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="年营业额"
              >{{ detail.salse | currency }} 万元</el-form-item
            >
          </el-col>
          <el-col :span="8">
            <el-form-item label="年采购额"
              >{{ detail.purchase | currency }} 万元</el-form-item
            >
          </el-col>
          <el-col :span="8">
            <el-form-item label="年投标规模"
              >{{ detail.bidAmount | currency }} 万元</el-form-item
            >
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="开户银行">
              {{ form.bankName }}
              <!-- <el-input
                v-model="form.bankName"
                placeholder="请选择开户银行"
                readonly
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="4">
            <el-form-item label="支行网点名称">
              {{ form.branchBank }}
              <!-- <el-input
                v-model="form.branchBank"
                placeholder="支行网点"
                readonly
              ></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="支行网点编号">
              {{ form.branchBankNo }}
              <!-- <el-input
                v-model="form.branchBankNo"
                placeholder="网点编号"
                readonly
              ></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="银行户名">
              {{ form.bankAccountName }}
              <!-- <el-input
                v-model="form.bankAccountName"
                placeholder="请输入银行户名"
                readonly
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="4">
            <el-form-item label="银行卡号">
              {{ form.bankAccount }}
              <!-- <el-input
                v-model="form.bankAccount"
                placeholder="请输入银行卡号"
                readonly
              >
              </el-input
            > -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn-row">
        <!-- <el-button
          type="primary"
          size="small"
          class="btn-row-item"
          @click="saveBankData"
          >修改开户信息</el-button
        > -->
        <el-button size="small" class="btn-row-item" @click="cancel"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/formatDate";

export default {
  data() {
    return {
      detail: null,

      form: {
        bankName: "", // 开户银行
        branchBank: "", // 支行网点
        branchBankNo: "", // 网点编号
        bankAccountName: "", // 银行户名
        bankAccount: "", // 银行卡号
      },
    };
  },
  mounted() {
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      this.$axios.get("/member/enterprisePage").then((res) => {
        if (res.state == 200) {
          this.companyData = res.data;
          this.getDetail(this.companyData.code);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDetail(code) {
      this.$axios.get(`/member/selectMemberDetail/${code}`).then((res) => {
        if (res.state == 200) {
          this.detail = res.data;
          this.detail.vipEndTime = formatDate(res.data.vipEndTime);
          this.form = {
            bankName: res.data.bankName, // 开户银行
            branchBank: res.data.branchBank, // 支行网点
            branchBankNo: res.data.branchBankNo, // 网点编号
            bankAccountName: res.data.bankAccountName, // 银行户名
            bankAccount: res.data.bankAccount, // 银行卡号
          };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    saveBankData() {
      let data = {
        bankName: this.form.bankName, // 开户银行
        branchBank: this.form.branchBank, // 支行网点
        branchBankNo: this.form.branchBankNo, // 网点编号
        bankAccountName: this.form.bankAccountName, // 银行户名
        bankAccount: this.form.bankAccount, // 银行卡号
      };
      //   qs.stringify(data, {allowDots: true}), {
      //       headers: {
      //         "Content-Type": "application/x-www-form-urlencoded",
      //       },
      //   let formdata = new FormData();
      //   Object.keys(data).forEach((key) => {
      //     formdata.append(key, data[key]);
      //   });
      this.$axios
        .post(`/member/saveOpenAccout`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.state == 200) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        });
    },
    cancel() {
      this.$router.push("/a-company/company/company-preserve/detail");
    },
  },
};
</script>
<style lang="scss" scoped>
.top-row {
  display: flex;
  .top-left {
    border: 1px solid #ededed;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;
    .company-picture {
      width: 123px;
      height: 123px;
      background: #ddd;
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }
    .company-base-content {
      height: 100%;
      flex: 1;
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tag-row {
        display: flex;
        justify-content: space-between;
        .tag-item {
          background: #004375;
          color: #ffffff;
          padding: 4px 12px;
        }
      }
    }
  }
  .top-right {
    margin-left: 24px;
    width: 253px;
    height: 187px;
    // background: linear-gradient(180deg, #4e8fff 0%, #2854ed 100%);

    border: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    // color: #ffffff;
    padding: 34px 31px;
    box-sizing: border-box;
  }
}

.border-wrap-box {
  margin-top: 24px;
  padding: 64px;
  box-sizing: border-box;
  border: 1px solid #ededed;
  margin-bottom: 20px;

  .tip-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    .el-icon-question {
      color: #fa6400;
      margin-right: 5px;
    }
  }
  .btn-row {
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-row-item {
      width: 151px;
    }
  }
}
</style>